import React from "react"

import { useRef, useEffect } from "react"
import { Container } from "react-bootstrap"
import MessageBubble from "./message-bubble"
import ChatInput from "./chat-input"

export default function ChatInterface({ messages, newMessage, setNewMessage, handleSendMessage, typing }) {
  const messagesEndRef = useRef(null)

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
  }

  useEffect(() => {
    scrollToBottom()
  }, [messages])

  // Check if there are any messages
  const hasMessages = messages.length > 0

  if (!hasMessages) {
    return (
      <Container className="d-flex flex-column justify-content-center align-items-center h-100">
        <h1 className="text-center mb-4">VeeCode Chat</h1>
        <div className="w-100" style={{ maxWidth: "500px" }}>
          <ChatInput newMessage={newMessage} setNewMessage={setNewMessage} handleSendMessage={handleSendMessage} disabled={typing} />
        </div>
      </Container>
    )
  }

  return (
    <Container fluid className="d-flex flex-column h-100 p-0">
      <div className="flex-grow-1 p-3 messages-container">
        {messages.map((message) => (
          <MessageBubble key={message.id} message={message} />
        ))}
        {typing && (
          <div className="typing-indicator">
            <p style={{color: "#fff"}}>Vee está digitando...</p>
          </div>
        )}
        <div ref={messagesEndRef} />
      </div>
      <div className="mt-auto">
        <ChatInput newMessage={newMessage} setNewMessage={setNewMessage} handleSendMessage={handleSendMessage} disabled={typing} />
      </div>
    </Container>
  )
}

