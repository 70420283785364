import React from "react"
import { Row, Col } from "react-bootstrap"
import { formatTime } from "../lib/utils"
import { ChatSquare, HandThumbsUp, HandThumbsDown, Link45deg, ArrowClockwise } from "react-bootstrap-icons"
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'

// Importar o CSS do KaTeX
//import 'katex/dist/katex.min.css'

export default function MessageBubble({ message }) {
  const isUser = message.sender === "user"

  return (
    <Row className="mb-4">
      <Col xs={12} className={`d-flex ${isUser ? "justify-content-end" : "justify-content-start"}`}>
        <div className="d-flex flex-column" style={{ maxWidth: "80%" }}>
          <div className={`message-content ${isUser ? "user-message" : "assistant-message"}`}>
            {isUser ? (
              message.content
            ) : (
              <ReactMarkdown
                remarkPlugins={[remarkGfm]}
                components={{
                  code: ({ node, inline, className, children, ...props }) => {
                    return inline ? (
                      <code className={className} {...props}>
                        {children}
                      </code>
                    ) : (
                      <pre className="code-block">
                        <code className={className} {...props}>
                          {children}
                        </code>
                      </pre>
                    )
                  },
                  table: ({ node, ...props }) => (
                    <table className="markdown-table" {...props} />
                  )
                }}
              >
                {message.content}
              </ReactMarkdown>
            )}
          </div>
        </div>
      </Col>
    </Row>
  )
}

