import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Form, Button, Col, Row, Table } from 'react-bootstrap';

import useAlertNotification from '../../../../context/AlertNotificationContext';
import useAuth from '../../../../context/AuthContext';

import ProjetosApiService from '../../../../services/api/ProjetosApiService';
import ResultCards from './ResultCards';
import * as R from "ramda";
import SimulacaoMargemApiService from '../../../../services/api/SimulacaoMargemApiService';


const DEFAULT_VALUE = '0,00';
const BASE_IMPOSTOS_SEM_RECEITA_BRUTA = '10,15';
const BASE_IMPOSTO_SEM_RESULTADO = '5,10';
const COMISSAO_VENDA = '4.30';
const RATEIO_ADMINISTRATIVO = '13.70';

const MOEDA = {
    "BRL": "Real",
    "USD": "Dólar",
    "EUR": "Euro"
}

const TIPO_CUSTO = {
    "INFRA_CLOUD": "Infraestrutura em Cloud",
    "OUTROS_SOFTWARES": "Outros softwares",
    "HORAS_EXTRAS": "Horas extras",
    "FORNECEDORES_TERCEIROS": "Fornecedores terceiros",
    "OUTROS": "Outros",
}

function MargemForm({ props, onCancel }) {
    const { user } = useAuth();
    const { email } = user;

    const { total: custoHHJira } = props.dataProjectDetail;

    const costs = props.dataProjectCosts

    // STATE
    const [isLoading, setIsLoading] = useState(false);
    const [isCalculate, setIsCalculate] = useState(false);
    const [errors, setErrors] = useState({});

    const [cotacoes, setCotacoes] = useState({})

    const [valorPrevistoVenda, setValorPrevistoVenda] = useState(DEFAULT_VALUE);
    const [nomeRelatorio, setNomeRelatorio] = useState('');
    const [custoHH, _] = useState(custoHHJira);

    const [custosIndiretos, setCustosIndiretos] = useState({
        0: {
            descricao: "",
            valor: DEFAULT_VALUE,
            moeda: "BRL",
            cotacao: DEFAULT_VALUE,
        }
    });

    const [formResult, setFormResult] = useState({
        impostos: 0,
        receitaLiquida: 0,
        custoTotalVenda: 0,
        resultadoBruto: 0,
        margemBruta: 0,
        rv: 0,
        rateioAdministrativo: 0,
        despesasOperacionais: 0,
        resultadoAntesIRCSLL: 0,
        impostoSemResultado: 0,
        resultadoLiquido: 0,
        margemLiquida: 0,
        totalCustoIndireto: 0,
        margemContribuicao: 0
    });

    const [bases, setBases] = useState({
        baseImpostoSemReceitaBruto: BASE_IMPOSTOS_SEM_RECEITA_BRUTA,
        baseImpostoSemResultado: BASE_IMPOSTO_SEM_RESULTADO,
        baseRV: COMISSAO_VENDA,
        baseRateioAdministrativo: RATEIO_ADMINISTRATIVO
    });

    const onChangeBases = (event) => {
        const { value, name } = event.target;
        setBases({...bases, [name]: mask(value ? value : DEFAULT_VALUE)});
    }

    const onChangeCustosIndiretos = (event, index) => {
        const { value, name } = event.target;

        let newValues = {}

        if (name === "moeda") {
            newValues = { ...newValues, cotacao: cotacoes[value] }
        }

        newValues = name === "valor" || name === "cotacao" ? {...newValues, [name]: mask(value)} : newValues = { ...newValues, [name]: value }

        setCustosIndiretos({
            ...custosIndiretos,
            [index]: { ...custosIndiretos[index], ...newValues }
        })

        setErrors({});
    }

    // CONTEXT
    const alert = useAlertNotification();

    const mask = (value) => {
        value = value.replace('.', '').replace(',', '').replace(/\D/g, '')

        const options = { minimumFractionDigits: 2 }

        const result = new Intl.NumberFormat('pt-BR', options).format(
            parseFloat(value) / 100
        )

        return result
    }

    useEffect(() => {
        document.getElementById("custoPrevistoVenda").focus();
        getCotacoes();
    }, [])

    const getCotacoes = async () => {
        const config = {
            method: 'get',
            url: `https://economia.awesomeapi.com.br/last/USD-BRL,EUR-BRL`
          };

          const result = await axios(config);
          setCotacoes({
              BRL: DEFAULT_VALUE,
              USD: mask(parseFloat(result.data['USDBRL'].bid).toFixed(2)),
              EUR: mask(parseFloat(result.data['EURBRL'].bid).toFixed(2))
          })

    }

    const convertValueInReal = (custoValue, custoMoeda) => {
        let convertedValue = custoValue;
        if (custoMoeda > 0 || custoMoeda < 0) {
            convertedValue = R.multiply(custoValue, custoMoeda);
        }

        return convertedValue;
    }

    const convertAndCalculateCustoIndiretoInArray = (list) => {
        let custos = [];
        for (const custo of list) {
            custos.push(convertValueInReal(parseFloat(custo.valor), parseFloat(custo.cotacao)));
        };

        return custos;
    }

    const calculaMargem = async () => {
        try {
            const custoVendaFormat = valorPrevistoVenda.replaceAll('.','').replace(',', '.');

            if (custoVendaFormat <= 0) {
                setErrors({ custoPrevistoVenda: "Informe uma valor de venda maior que zero." });
                return;
            }

            // TODO: em investigação do porque de não chegar o request à API
            // const resp = await ProjetosApiService.calculateMargem({
            //     custoHH,
            //     receitaBruta: custoVendaFormat,
            //     baseRV: parseFloat(bases.baseRV.replace(',', '.') / 100),
            //     baseImpostoSemResultado: parseFloat(bases.baseImpostoSemResultado.replace(',', '.') / 100),
            //     baseRateioAdministrativo: parseFloat(bases.baseRateioAdministrativo.replace(',', '.') / 100),
            //     baseImpostoSemReceitaBruto: parseFloat(bases.baseImpostoSemReceitaBruto.replace(',', '.') / 100),
                // custosIndiretos: Object.keys(custosIndiretos).map(key => {
                //     return {
                //         valor: parseFloat(custosIndiretos[key].valor.replaceAll('.', '').replace(',', '.')),
                //         cotacao: custosIndiretos[key].cotacao.replace(',', '.')
                //     }
                // })
            // })
            // const result = resp.data.data[0]

            const convertedCustosIndiretos = convertAndCalculateCustoIndiretoInArray(costs);
            // const convertedCustosIndiretos = convertAndCalculateCustoIndiretoInArray(Object.keys(custosIndiretos).map(key => {
            //     return {
            //         valor: parseFloat(custosIndiretos[key].valor.replaceAll('.', '').replace(',', '.')),
            //         cotacao: custosIndiretos[key].cotacao.replace(',', '.')
            //     }
            // }));

            const totalCustoIndireto = R.sum(convertedCustosIndiretos);

            const totalImpostos = R.multiply(custoVendaFormat, parseFloat(bases.baseImpostoSemReceitaBruto.replace(',', '.') / 100));

            const totalReceitaLiquida = custoVendaFormat - totalImpostos;

            const totalResultadoBruto = totalReceitaLiquida - custoHH - totalCustoIndireto;

            const custoTotalVenda = R.sum([custoHH, totalCustoIndireto]);

            const totalMargemBruta = R.divide(totalResultadoBruto, custoVendaFormat);

            const totalRv = R.multiply(custoVendaFormat, parseFloat(bases.baseRV.replace(',', '.') / 100));

            const totalRateioAdministrativo = R.multiply(custoVendaFormat, parseFloat(bases.baseRateioAdministrativo.replace(',', '.') / 100));

            const totalDespesasOperacionais = R.sum([totalRv, totalRateioAdministrativo]);

            const totalAntesIRCSLL = totalResultadoBruto - totalDespesasOperacionais;

            const totalImpostoSemResultado = R.multiply(custoVendaFormat, parseFloat(bases.baseImpostoSemResultado.replace(',', '.') / 100));

            const totalResultadoLiquido = totalAntesIRCSLL - totalImpostoSemResultado;

            const totalMargemLiquida = R.divide(totalResultadoLiquido, custoVendaFormat);

            const margemContribuicao = R.divide((totalReceitaLiquida - custoTotalVenda), totalReceitaLiquida)

            setFormResult({
                impostos: totalImpostos,
                receitaLiquida: totalReceitaLiquida,
                resultadoBruto: totalResultadoBruto,
                custoTotalVenda: custoTotalVenda,
                margemBruta: totalMargemBruta,
                rv: totalRv,
                rateioAdministrativo: totalRateioAdministrativo,
                despesasOperacionais: totalDespesasOperacionais,
                resultadoAntesIRCSLL: totalAntesIRCSLL,
                impostoSemResultado: totalImpostoSemResultado,
                resultadoLiquido: totalResultadoLiquido,
                margemLiquida: totalMargemLiquida,
                totalCustoIndireto: totalCustoIndireto,
                margemContribuicao: margemContribuicao
            })

            setIsCalculate(true);

        } catch (error) {
            console.log("[ERROR] [MargemForm.calculaMargem]:", error);
            alert.error("Erro ao calcular a margem do projeto.");
        }
    }

    const salvaRelatorio = async () => {
        try {
            if (!nomeRelatorio.trim()) {
                setErrors({ nomeRelatorio: "Informe o nome do relatório" });
                document.getElementById("nomeRelatorio").focus();

                return;
            }

        const relatorioExistente = await ProjetosApiService.getNameMargem(nomeRelatorio.trim());

        if (relatorioExistente.data.found) {
            setErrors({ nomeRelatorio: "Já existe um relatório com este nome." });
            document.getElementById("nomeRelatorio").focus();
            return;
        }

            const response = await ProjetosApiService.salvarMargem({
                ...formResult,
                custoHH,
                email,
                receitaBruta: parseFloat(valorPrevistoVenda.replaceAll('.', '').replace(',', '.')),
                nomeRelatorio,
                projeto: props.project,
                periodoInicio: props.periodoInicio,
                periodoFim: props.periodoFim,
                custosIndiretos: costs.map(custo => {
                    return {
                        descricao: custo.descricao,
                        valor: custo.valor,
                        moeda: custo.moeda,
                        cotacao: custo.cotacao,
                        tipo_custo: custo.tipo_custo
                    }
                })
            });

            const { status } = response;

            if (status !== 200) {
                alert.error("Erro ao salvar a margem do projeto.");
                return;
            }

            alert.success(`Relatório '${nomeRelatorio}' de margem de projeto salvo com sucesso`);
            setIsLoading(false);
            onCancel();

        } catch (error) {
            console.log("[ERROR] [MargemForm.salvaRelatorio]:", error);
            alert.error("Erro ao salvar a margem do projeto.");
        }
    }

    const criarSimulacao = async () => {
        try {
            if (!nomeRelatorio.trim()) {
                setErrors({ nomeRelatorio: "Informe o nome do relatório" });
                document.getElementById("nomeRelatorio").focus();

                return;
            }

            setIsLoading(true);

            const response = await SimulacaoMargemApiService.salvarSimulacaoMargem({
                ...formResult,
                custoHH,
                email,
                receitaBruta: parseFloat(valorPrevistoVenda.replaceAll('.', '').replace(',', '.')),
                nomeRelatorio,
                projeto: props.project,
                periodoInicio: props.periodoInicio,
                periodoFim: props.periodoFim,
                custosIndiretos: costs.map(custo => {
                    return {
                        descricao: custo.descricao,
                        valor: custo.valor,
                        moeda: custo.moeda,
                        cotacao: custo.cotacao,
                        tipo_custo: custo.tipo_custo
                    }
                })
            });

            const { status } = response;

            if (status !== 200) {
                alert.error("Erro ao criar simulação.");
                return;
            }

            alert.success(`Simulação '${nomeRelatorio}' de margem de projeto salvo com sucesso`);
            setIsLoading(false);
            onCancel();

        } catch (error) {
            console.log("[ERROR] [MargemForm.criarSimulacao]:", error);
            alert.error("Erro ao criar simulação da margem do projeto.");
        }
    }

    return (
        <div>
            <Form noValidate>
                <Row style={{ marginBottom: 10 }}>
                    <Col>
                        <Form.Label>Custo Hora Colaborador</Form.Label>
                        <Form.Control
                            name="custoColaborador"
                            type="currency"
                            readOnly={true}
                            value={custoHH.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                            autoComplete="off"
                        />
                    </Col>
                    <Col></Col>
                </Row>

                <Row style={{ marginBottom: 10 }}>
                    <Col>
                        <Form.Label>% Impostos s/ Resultado Bruto</Form.Label>
                        <Form.Control
                            name="baseImpostoSemReceitaBruto"
                            type="text"
                            onChange={onChangeBases}
                            value={bases.baseImpostoSemReceitaBruto}
                            autoComplete="off"
                            maxLength={5}
                        />
                    </Col>
                    <Col>
                        <Form.Label>% Impostos antes IR/CSLL</Form.Label>
                        <Form.Control
                            name="baseImpostoSemResultado"
                            type="text"
                            onChange={onChangeBases}
                            value={bases.baseImpostoSemResultado}
                            autoComplete="off"
                            maxLength={5}
                        />
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <Form.Label>% RV</Form.Label>
                        <Form.Control
                            name="baseRV"
                            type="text"
                            onChange={onChangeBases}
                            value={bases.baseRV}
                            autoComplete="off"
                            maxLength={5}
                        />
                    </Col>
                    <Col>
                        <Form.Label>% Rateio Administrativo</Form.Label>
                        <Form.Control
                            name="baseRateioAdministrativo"
                            type="text"
                            onChange={onChangeBases}
                            value={bases.baseRateioAdministrativo}
                            autoComplete="off"
                            maxLength={5}
                        />
                    </Col>
                </Row>


                <Row>
                    <Col>
                        <Form.Label>Valor Total de Nota Fiscal</Form.Label>
                        <Form.Control
                            id="custoPrevistoVenda"
                            name="custoPrevistoVenda"
                            type="text"
                            onChange={(e) => {
                                const { value } = e.target;
                                setValorPrevistoVenda(mask(value ? value : DEFAULT_VALUE));
                                setErrors({});
                            }}
                            value={valorPrevistoVenda}
                            autoComplete="off"
                            isInvalid= { !!errors.custoPrevistoVenda }
                        />
                        <Form.Control.Feedback type="invalid">
                            { errors.custoPrevistoVenda }
                        </Form.Control.Feedback>
                    </Col>
                </Row>

                { costs.length > 0 && (
                    <>
                        <hr />
                        <div className="d-flex justify-content-between">
                            <h5>Custos Indiretos</h5>
                        </div>

                        <Table striped bordered>
                            <thead>
                                <tr>
                                    <th>Tipo</th>
                                    <th>Valor</th>
                                    <th>Moeda</th>
                                    <th>Valor convertido</th>
                                </tr>
                            </thead>
                            <tbody>
                                {costs.map(cost => (
                                    <tr key={cost.id}>
                                        <td>{TIPO_CUSTO[cost['tipo_custo']]}</td>
                                        <td>{mask(cost.valor)}</td>
                                        <td>{MOEDA[cost.moeda]}</td>
                                        <td>
                                            {convertValueInReal(parseFloat(cost.valor), parseFloat(cost.cotacao)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </>
                )}

                <Button style={{ marginTop: 15 }} onClick={() => calculaMargem()} >
                    Calcular
                </Button>
            </Form>

            {
                isCalculate && (
                    <>
                        <ResultCards valorPrevistoVenda={valorPrevistoVenda} custoHH={custoHH} formResult={formResult} />
                        <div className='mt-3'>
                            <span style={{ marginTop: 5 }}>
                                <Row>
                                    <Col style={{ display: 'grid' }}>
                                        <Form.Group>
                                            <Form.Control
                                                id="nomeRelatorio"
                                                name="nomeRelatorio"
                                                placeholder="Nome do relatório"
                                                type="text"
                                                onChange={(e) => {
                                                    const { value } = e.target;
                                                    setNomeRelatorio(value);
                                                    setErrors({});
                                                }}
                                                value={nomeRelatorio}
                                                autoComplete="off"
                                                isInvalid= {!!errors.nomeRelatorio}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                { errors.nomeRelatorio }
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        <div>
                                            <Button className='mr-3' onClick={() => salvaRelatorio()}>
                                                Salvar
                                            </Button>
                                            <Button variant='info' onClick={() => criarSimulacao()}>
                                                Criar simulação
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>

                            </span>
                        </div>

                    </>
                )
            }
        </div>
    )
}

export default MargemForm;
