import React from "react"

import { Form, Button, InputGroup } from "react-bootstrap"
import { Plus, Globe, Lightbulb, ThreeDotsVertical, ArrowUp } from "react-bootstrap-icons"

export default function ChatInput({ newMessage, setNewMessage, handleSendMessage, disabled }) {
  return (
    <div className="p-3 chat-input-container">
      <Form onSubmit={handleSendMessage}>
        <InputGroup className="chat-input-group">
          <Form.Control
            type="text"
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            placeholder="Pergunte-me o que você precisa..."
            className="chat-input"
            disabled={disabled}
            style={styles.chatInput}
          />
          <Button 
            type="submit" 
            variant="light" 
            className="send-button" 
            disabled={!newMessage.trim() || disabled}
            style={{
              ...styles.sendButton,
              opacity: !newMessage.trim() || disabled ? 0.7 : 1
            }}
          >
            <ArrowUp size={20} style={styles.submitButton}/>
            <span className="visually-hidden">Enviar</span>
          </Button>
        </InputGroup>
      </Form>
    </div>
  )
}

const styles = {
  sendButton: {
    backgroundColor: "#fff",
    borderRadius: "9999px",
    width: "40px",
    height: "40px",
    backgroundColor: "black",
    opacity: 1,
    color: "#fff",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  chatInput: {
    backgroundColor: "#fff",
    borderRadius: "9999px",
    width: "40px",
    height: "40px",
    border: "none",
    color: "#000",
  }
}
