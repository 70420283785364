import React, { useEffect, useState, useRef } from "react"
import { Container } from "react-bootstrap"
import ChatInterface from "./components/chat-interface"
import VeeLogo from "./assets/vlogo.jsx"
import "./App.css"

export default function ChatBotView() {
  const [messages, setMessages] = useState([])
  const [newMessage, setNewMessage] = useState("")
  const [typing, setTyping] = useState(false)
  const [chatVisible, setChatVisible] = useState(true)
  const [threadId, setThreadId] = useState("")
  const [showGreeting, setShowGreeting] = useState(true)
  const [isHovered, setIsHovered] = useState(false)
  
  // Inicializa a mensagem de boas-vindas
  useEffect(() => {
    // Inicializa a mensagem de boas-vindas se não houver mensagens
    if (messages.length === 0) {
      const welcomeMessage = {
        id: Date.now().toString(),
        content: "Olá, eu sou a Vee e vou te ajudar aqui no Convert! O que você precisa exatamente?",
        sender: "assistant",
        timestamp: new Date(),
        reactions: { copied: false, liked: false, disliked: false },
      }
      setMessages([welcomeMessage])
    }
    
    // Timer para esconder a mensagem de boas-vindas ao passar o mouse
    const timer = setTimeout(() => {
      setShowGreeting(false)
    }, 2000)

    return () => clearTimeout(timer)
  }, [])

  // Cria uma nova thread ao iniciar
  useEffect(() => {
    const createThread = async () => {
      try {
        const response = await fetch(
          "https://hml.agentia.convert.vertigo.com.br/api/v2/threads",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "apikey": "testehml"
            }
          }
        )
        const data = await response.json()
        setThreadId(data.thread_id)
      } catch (error) {
        console.error("Erro ao criar thread:", error)
      }
    }
    
    createThread()
  }, [])

  // Eventos para mostrar/esconder a mensagem quando o mouse passa pelo logo
  const handleMouseEnter = () => {
    setIsHovered(true)
    setShowGreeting(true)
  }

  const handleMouseLeave = () => {
    setIsHovered(false)
    setShowGreeting(false)
  }

  // Processa a mensagem do usuário
  const handleSendMessage = async (e) => {
    e.preventDefault()
    if (newMessage.trim() === "") return

    const userMessage = {
      id: Date.now().toString(),
      content: newMessage,
      sender: "user",
      timestamp: new Date(),
      reactions: { copied: false, liked: false, disliked: false },
    }

    setMessages((prev) => [...prev, userMessage])
    setNewMessage("")
    setTyping(true)

    // Processa a mensagem para a API
    try {
      const message = {
        content: newMessage
      }
      const response = await fetch(
        `https://hml.agentia.convert.vertigo.com.br/api/v2/threads/${threadId}/messages`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "apikey": "testehml"
          },
          body: JSON.stringify(message)
        }
      )
      
      const data = await response.json()
      
      const assistantMessage = {
        id: (Date.now() + 1).toString(),
        content: data.content,
        sender: "assistant",
        timestamp: new Date(),
        reactions: { copied: false, liked: false, disliked: false },
      }
      
      setMessages((prev) => [...prev, assistantMessage])
    } catch (error) {
      console.error("Erro ao enviar mensagem:", error)
      // Resposta de fallback em caso de erro
      const fallbackMessage = {
        id: (Date.now() + 1).toString(),
        content: "Desculpe, estou enfrentando problemas de conexão. Pode tentar novamente mais tarde?",
        sender: "assistant",
        timestamp: new Date(),
        reactions: { copied: false, liked: false, disliked: false },
      }
      setMessages((prev) => [...prev, fallbackMessage])
    } finally {
      setTyping(false)
    }
  }

  return (
    <>
      {!chatVisible ? (
        <div
          className="logoIaChat"
          onClick={() => setChatVisible(true)}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <VeeLogo className={`${isHovered ? 'hovered' : ''}`} />
          {showGreeting && (
            <div className="messageVee">
              <p>Olá, sou a Vee e estou aqui pra te ajudar!</p>
            </div>
          )}
        </div>
      ) : (
        <Container fluid className="p-0 min-vh-100 text-white d-flex justify-content-center main-container" style={{backgroundColor: "#fff !important"}}>
          <div className="chat-container">
            <ChatInterface
              messages={messages}
              newMessage={newMessage}
              setNewMessage={setNewMessage}
              handleSendMessage={handleSendMessage}
              typing={typing}
            />
          </div>
        </Container>
      )}
    </>
  )
}

