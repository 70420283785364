import React from 'react';

export const VeeLogo = () => (
  <svg 
    xmlns="http://www.w3.org/2000/svg" 
    viewBox="0 0 100 100" 
    width="40" 
    height="40"
  >
    <circle cx="50" cy="50" r="45" fill="#5A67D8" />
    <text 
      x="50" 
      y="65" 
      fontFamily="Arial" 
      fontSize="50" 
      fontWeight="bold" 
      fill="white" 
      textAnchor="middle"
    >
      V
    </text>
  </svg>
);

export default VeeLogo; 